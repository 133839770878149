import React from 'react';
import '../Modals.scss';
import '../../../index.scss';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

function Call() {
  return (
    <div className='backdrop'>
        <div className='form'>
            <div className='inputs'>
                <input type='text' placeholder='Name'/>
                <input type='email' placeholder='E-mail'/>
                <PhoneInput
                                // ref={phoneInput}
                                placeholder="Phone number"
                                // value={userPhoneNumber}
                                // onChange={setPhone}
                                id="userPhoneNumber"/>
            </div>
        </div>
    </div>
  )
}

export default Call