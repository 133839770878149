import './App.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Authorization from './components/Authorization/Authorization';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Programs from './components/Programs/Programs';
import Call from './components/Forms/Call/Call';

const routes = createBrowserRouter([
  // {
  //   path: '/',
  //   Component: Authorization
  // },
  {
    path: '/',
    Component: Programs
  }
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={routes} />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* <Call/> */}
    </div>
  );
}

export default App;
