import React, { useState } from 'react'
import './Collapse.scss'

function Collapse({head, list}) {
    const [isCollapsed, setCollapsed] = useState(true);

    const toggle = () => {
        setCollapsed(!isCollapsed)
    }

  return (
    <div className={"menu_item collapse " + (isCollapsed ? "collapsed" : "")}>
        <div className='head' onClick={toggle}>{head}</div>
        <div className='body'>
            {list.map((item, index) => (
                <a className='menu_item' href={item.href} key={index}>{item.title}</a>
            ))}
        </div>
    </div>
  )
}

export default Collapse