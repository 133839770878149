import React from 'react';
import './Programs.scss';
import pp from '../../assets/programs/pp.png';
import ss from '../../assets/programs/ss.png';
import wl from '../../assets/programs/wl.png';
import st from '../../assets/programs/st.png';
import yp from '../../assets/programs/yp.png';
import Authorization from '../Authorization/Authorization';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';

function Programs() {
  return (
    <div className='page'>
        <Navigation/>
        <main>
          <section className='content'>
            <h1>Join the SuperMind Club — 24/7 AI Research-Backed Coaching with Live Support!</h1>
            <p className='subtitle'>Our AI-coaches, integrated into WhatsApp, will confidentially support you 24/7 with research-backed programs, complemented by online group meetings.<br></br><b>See the results in just 1 month!</b></p>
          </section>
          <section className='content'>
            <div id='programs'>
              <div className='program'>
                <img className='icon' src={pp}/>
                <div className='title'>Peak Performance</div>
              </div>
              <div className='program'>
                <img className='icon' src={ss}/>
                <div className='title'>Stop Smoking</div>
              </div>
              <div className='program'>
                <img className='icon' src={wl}/>
                <div className='title'>Weight Loss</div>
              </div>
              <div className='program'>
                <img className='icon' src={st}/>
                <div className='title'>Stressful Talks</div>
              </div>
              <div className='program'>
                <img className='icon' src={yp}/>
                <div className='title'>Your Program</div>
              </div>
            </div>
          </section>
          <section className='rainbow'>
            <div className='content'>
              <h2>Limited time only! Test the trial version with no credit card!</h2>
            </div>
          </section>
          <section className='content'>
            <div id="registration" className='registration'>
              <Authorization/>
            </div>
          </section>
          <section className='content inputs'>
            <h2>More Information? Subscribe or book a call!</h2>
            <p className='subtitle'>Get a free consultation with our experts, or subscribe to stay updated on new programs, discounts, special offers, and insights!</p>
            <button className='btn primary submit'>Contact Us</button>
          </section>
        </main>
        <Footer/>
    </div>
  )
}

export default Programs